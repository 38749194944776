@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-decoration {
		-webkit-appearance: none;
		appearance: none;
	}
}
